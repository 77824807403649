import { useEffect, useState, useContext } from 'react';
import { flushSync } from 'react-dom';
import { Transaction } from '@sentry/types';

import { SessionContext } from '@providers/SessionProvider';
import keysOf from 'utils/keysOf';

type RetType<T extends Record<string, () => Promise<unknown>>> = Partial<{ [P in keyof T]: Awaited<ReturnType<T[P]>> }>;

export const useClientSideProps = <R, T extends Record<string, () => Promise<R>>>(
  dataCallbacks: T,
  order: (keyof T)[] = keysOf(dataCallbacks) || [],
  dependencyArray: unknown[] = [],
  transaction: Transaction | undefined = undefined
): { data: RetType<T>; isLoading: boolean } => {
  const [data, setData] = useState<RetType<T>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { sessionKey } = useContext(SessionContext);

  useEffect(() => {
    const handler = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of order) {
        const span = transaction?.startChild({ op: key as string });
        // eslint-disable-next-line no-await-in-loop
        const result = await dataCallbacks[key]();
        span?.finish();
        flushSync(() => {
          setData((currentData) => ({ ...currentData, [key]: result }));
        });
      }

      setIsLoading(false);
    };

    if (sessionKey) {
      handler();
    }
  }, [...dependencyArray, sessionKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, isLoading };
};

export const useFinishTransaction = (transaction: Transaction, isLoading: boolean): void => {
  useEffect(() => {
    if (!isLoading) {
      transaction.finish();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
};
